import React, { Fragment } from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'

import './../styles/pageSpecific/careers.scss'
import { Icon } from '@iconify/react';

import EEOLawFlyer from './../pdfs/eeopost.pdf'
import EEOSupplementFlyer from './../pdfs/OFCCP_EEO_Supplement_Final_JRF_QA_508c.pdf'
import CollapsibleBox from '../components/UI/Containers/CollapsibleBox/CollapsibleBox'
import SimpleButton from '../components/UI/Buttons/SimpleButton/SimpleButton'

import MarineSeal from './../images/militarySeals/Seal_of_the_United_States_Marine_Corps.inline.svg'
import NavySeal from './../images/militarySeals/Seal_of_the_United_States_Department_of_the_Navy.inline.svg'
import AirforceSeal from './../images/militarySeals/Seal_of_the_United_States_Department_of_the_Air_Force.inline.svg'
import CoastGuardSeal from './../images/militarySeals/Seal_of_the_United_States_Coast_Guard.inline.svg'
import ArmySeal from './../images/militarySeals/Emblem_of_the_U.S._Department_of_the_Army.inline.svg'

import ESGRlogo from './../images/veteranPrograms/ESGR.png'
import MilitarySpouselogo from './../images/veteranPrograms/Military-Spouse.png'
import eVerifyFlyer from './../pdfs/ier-flyer.jpg'
import eVerifyFlyerSpanish from './../pdfs/ier-flyer-espanol.jpg'
import EverifyLogo from './../images/benefits/E-Verify_logo.inline.svg'

const EqualOpportunityEmployerPage = () => {
    const pageInfo = {
        pageType:'interior',
        title1: 'Equal Opportunity',
        title2: 'Employer',
        breadcrumbs: ['Careers'],
        ctaButton: false,
        ctaButtonData:{
            text: '',
            url: ''
          }
    }
 
    return(
        <GeneralPageLayout pageData={pageInfo}> 
           <div className="content">
               <SectionContainer id='introEOE' color='white' type='contained'>
                   <p className={`basicText narrowContentCol`}>
                        We are committed to adding diverse, talented team members who bring innovation to our organization. If this describes you, please click here to find the right career opportunity for you. Opportunities exist at all levels for motivated highly-skilled experts seeking a supportive, rewarding and challenging team environment.
                   </p>
                   <div className={`btnContainer justifyChildrenStart inlineChildren_mobile narrowContentCol `}>
                       <SimpleButton 
                            location={`external`}
                            type={`gray`}
                            url={EEOLawFlyer}
                            icon={`bi:cloud-download`}
                            text={`Equal Employment Opportunity Is The Law`}
                            rel="noreferrer" target='_blank'/>
                        <SimpleButton 
                            location={`external`}
                            type={`gray`}
                            url={EEOSupplementFlyer}
                            icon={`bi:cloud-download`}
                            text={`(EEO Supplement)`}
                            rel="noreferrer" target='_blank'/>
                   </div>
               </SectionContainer>

               <SectionContainer id='eoeOpportunities' color='white' type='contained'>
                    <SectionHeader color='navy' alignment='center' size='med' trim={true} tag={`  `}>Equal Employment <span>Opportunities</span></SectionHeader>
                    <p className={`basicText thinText narrowContentCol`}>
                        Precise Systems, Inc. provides equal employment opportunities to all applicants and employees and prohibits discrimination against any applicant or employee on the basis of race, ethnic or national origin, creed, religious or political belief or affiliation, gender, sexual orientation, marital status, age, veteran status, or physical or mental disability. Precise Systems, Inc. is an Affirmative Action/Equal Opportunity Employer and encourages minorities, women, disabled and veterans and protected veterans to apply for job openings within our company. Precise Systems participates in E-Verify.
                     </p>
                     <CollapsibleBox 
                        id='EEOstatement'
                        header='EQUAL EMPLOYMENT OPPORTUNITY POLICY STATEMENT'
                        tag=''
                        footnote='The plan is available for inspection in the Human Resources Department (Lexington Park, MD) Monday thru Friday from 9:00am to 3:00pm.'
                        classes={['narrowContentCol']}>
                            <Fragment>
                                <p>Precise Systems has a long-standing record of providing equal employment opportunities to all its employees. Precise Systems does not discriminate based on age, gender, sexual orientation, race, national origin, religion, marital status, family responsibilities, political affiliation, veteran status, genetic information or disability that does not prohibit performance of essential job functions. This policy applies to all the terms and conditions of employment including but is not limited to: recruitment, hiring, transfers, promotions, training, compensation, leave of absences, terminations, layoffs, and/or employment recalls.</p>
                                <p>Precise System’s equal employment opportunity and affirmative action policies and objectives also apply to its work with customers, consultants, vendors, subcontractors, and any other entity that is affiliated with Precise Systems.</p>
                                <p>It is the responsibility of all Precise Systems employees to fully adhere to the policies and objectives of equal employment opportunity. Precise Systems has developed and implemented an Affirmative Action Plan to support its commitment to the principle of equal employment opportunities. Any violation of Precise System’s Equal Employment Opportunity and Affirmative Action Policies should be brought to the attention of the employee’s supervisor or the Human Resources department. Precise Systems will investigate all reports of such conduct. Employees found in violation of the Equal Employment Opportunity Policies will be subject to the full range of disciplinary action. The Human Resource department will monitor and make reports on a periodic and continuing basis to both Senior Management and appropriate government entities as required.</p>
                                <p>Precise Systems prohibits retaliation against any employee for filing a complaint in good faith under this policy or for assisting in the complaint investigation. If after investigating any complaint of discrimination, Precise Systems determines that the complaint is not bona fide, or the employee has provided false information regarding the complaint, disciplinary action may be taken against the individual who filed the complaint or furnished false information.</p>
                            </Fragment>
                    </CollapsibleBox>
                </SectionContainer>

                {/* Committed Vets */}
                <SectionContainer id='eoeVet' color='navy' type='fullWidth'>
                    <SectionHeader color='white' alignment='center' size='med' trim={true} tag={`  `}>Commited To <span>Veterans</span></SectionHeader>
                    <p className={`basicText thinText lightText narrowContentCol`}>
                        Precise Systems is a proud employer to American Veterans. We value the experience that Veterans bring to our organization and recognize that the qualities and experience they possess exemplify the Core Values of our company and what drives our continued success. We are extremely proud to be an organization which employs many Veterans. These highly skilled experts provided invaluable service to our Country and continue to do so as they support our customers mission. We are pleased to partner with Warriors to Work and the Wounded Warrior Project, as well as, Hero2Hired, VETBridge and Employer Support of the Guard and Reserve.
                     </p>
                     <section id='militarySeals' className={`inlineChildren_noStack flexWrap narrowContentCol`}>
                        <MarineSeal alt='US Marine Seal'/>
                        <NavySeal alt='US Navy Seal'/>
                        <CoastGuardSeal alt='US Coast Guard Seal'/>
                        <ArmySeal alt='US Army Seal'/>
                        <AirforceSeal alt='US Air Force Seal'/>
                     </section>
                     <section id='vetProgramsLogos' className={`inlineChildren_noStack flexWrap narrowContentCol`}>
                        <img loading='lazy' src={ESGRlogo} alt={`ESGR veteran program logo`} width='100px' height='auto'/>
                        <img loading='lazy' src={MilitarySpouselogo} alt={`Military Spouse veteran program logo`}  width='100px' height='auto'/>
                    </section>
                </SectionContainer>

                {/* Our Accessibility */}
                <SectionContainer id='eoeAccessibility' color='gray' type='fullWidth'>
                    <SectionHeader color='navy' alignment='center' size='med' trim={true} tag={`  `}>Our <span>Accessibility</span></SectionHeader>
                    <p className={`basicText thinText narrowContentCol`}>
                        If you are a job seeker with a disability and require an accommodation to navigate our careers site or apply for one of our jobs, please submit a request by telephone or via email.
                    </p>
                    <section id='contactCards' className={`inlineChildren_noStack flexWrap narrowContentCol`}>
                       <a href='tel:+301-863-3673' className='contactCard inlineChildren_column alignChildrenCenter'>
                            <Icon className={`contactType_icon`} icon="clarity:phone-handset-solid" inline={true} width={40}/>
                            Request accommodation by telephone
                            <small>contact us at: 301-863-3673</small>
                       </a>
                       <a href='mailto:accessibility@goprecise.com' className='contactCard inlineChildren_column alignChildrenCenter'>
                            <Icon className={`contactType_icon`} icon="clarity:email-solid" inline={true} width={40}/>
                            Request accommodation by email
                            <small>contact us at: accessibility@goprecise.com</small>
                       </a>
                    </section>
                    <p className={`footnote simple narrowContentCol`}>
                        *Please note: the dedicated email and telephonic options listed above are only for job seekers with disabilities requesting an accommodation. Please do not use the dedicated phone line or email box to check on the status of your job application
                    </p>
                </SectionContainer>

                {/* We Participate */}
                <SectionContainer id='eoeParticipate' color='white' type='contained'>
                    <SectionHeader color='navy' alignment='center' size='med' trim={true} tag={`  `}>We <span>Participate</span></SectionHeader>
                    <p className={`basicText thinText narrowContentCol`}>
                        Effective 8 September 2009, federal law requires Precise Systems to use the Internet-based system E-Verify, operated by the Department of Homeland Security (DHS), U.S. Citizenship and Immigration Services (USCIS), to confirm work authorization in the U.S. verifying the employment eligibility of our employees. The information provided by the employee on his or her Form I-9, will be checked electronically in E-Verify against records contained in DHS and Social Security Administration (SSA) databases. Please see the below posters for details or contact Precise Systems Human Resources department for more information.
                     </p>
                     <CollapsibleBox 
                        id='eVerifyParticipants'
                        header='This Organization Participates in E–Verify'
                        tag='NOTICE: Federal law requires all employers to verify the identity and employment eligibility of all persons hired to work in the United States.'
                        footnote='For more information on E–Verify, please contact DHS at: 1-888-464-4218'
                        classes={['narrowContentCol']}>
                            <Fragment>
                                <p>This SWA will provide the Social Security Administration (SSA) and, if necessary, the Department of Homeland Security (DHS), with information from each applicant's Form I-9 to confirm work authorization.</p>
                                <p>SWA and employers may not use E–Verify to reverify current employees and may not limit or influence the choice of documents presented for use on the Form I-9.</p>
                                <p><em><strong>IMPORTANT:</strong></em> If the Government cannot confirm that you are authorized to work, this SWA is required to provide you written instructions and an opportunity to contact SSA and/or DHS before taking adverse action against you, including terminating your employment</p>
                                <p>If you believe that your SWA has violated its responsibilities under this program or has discriminated against you during the verification process based upon your national origin or citizenship status, please call the Office of Special Counsel for Immigration Related Unfair Employment Practices at <a href='tel:+1-800-255-7688' className={`btn_simpleText red inline`}>1-800-255-7688</a> (<strong>TDD:</strong> <a href='tel:+1-800-237-2515' className={`btn_simpleText red inline`}>1-800-237-2515</a>).</p>
                                <div>
                                    <div className='inlineChildren justifyChildrenStart alignChildrenCenter'>
                                        <EverifyLogo id='eVerifyLogo' alt='E-Verify Logo'/>
                                        <a href={eVerifyFlyer} rel="noreferrer" target='_blank' className={`btn_simpleText gray trim`}>View IER Flyer<Icon className={``} icon="eva:arrow-ios-forward-fill" inline={true} width={16}/></a> 
                                        <a href={eVerifyFlyerSpanish} rel="noreferrer" target='_blank' className={`btn_simpleText gray trim`}>View IER Flyer (Español)<Icon className={``} icon="eva:arrow-ios-forward-fill" inline={true} width={16}/></a>
                                    </div>
                                </div>
                                
                            </Fragment>
                    </CollapsibleBox>
                    <CollapsibleBox 
                        id='eVerifyRights'
                        header="If You Have The Right To Work Don't Let Anyone Take It Away"
                        tag='There are laws to protect you from discrimination in the workplace.'
                        footnote=''
                        classes={['narrowContentCol']}>
                            <Fragment>
                                <p><strong>You should know that...</strong></p>
                                <div>
                                    <ul>
                                        <li>In most cases, employers cannot deny you a job or fire you because of your national origin or citizenship status or refuse to accept your legally acceptable documents.</li>
                                        <li>Employers cannot reject documents because they have a future expiration date.</li>
                                        <li>Employers cannot terminate you because of E-Verify without giving you an opportunity to resolve the problem.</li>
                                        <li>In most cases, employers cannot require you to be a U.S. citizen of a lawful permanent resident.</li>
                                    </ul>
                                </div>
                                <p>
                                        <a href={eVerifyFlyer} rel="noreferrer" target='_blank' className={`btn_simpleText gray trim`}>View IER Flyer <Icon className={``} icon="eva:arrow-ios-forward-fill" inline={true} width={16}/></a> 
                                        <a href={eVerifyFlyerSpanish} rel="noreferrer" target='_blank' className={`btn_simpleText gray trim`}>View IER Flyer (Español) <Icon className={``} icon="eva:arrow-ios-forward-fill" inline={true} width={16}/></a>
                                </p>
                                <div>    
                                    <p className='footnote'>
                                        <span>If any of these things happen to you, contact the Immigrant and Employee Rights Section (IER).</span>
                                        <br /><br />
                                        For assistance in your own language<br/>
                                        <strong>Phone:</strong>  <a href='tel:+1-800-255-7688' className={`btn_simpleText red inline`}>1-800-255-7688</a><br/>
                                        <strong>TTY:</strong>  <a href='tel:+1-800-237-2515' className={`btn_simpleText red inline`}>1-800-237-2515</a><br/>
                                        <strong>Email:</strong>  <a href='mailto:IER@usdoi.gov' className={`btn_simpleText red inline`}>IER@usdoi.gov</a><br/>
                                        <strong>Or Write to:</strong> U.S. Department of Justice - CRT Immigrant and Employee Rights - NYA, 950 Pennsylvania Ave., NW Washington, DC 20530
                                    </p>
                                </div>
                            </Fragment>
                    </CollapsibleBox>
                </SectionContainer>

                {/* Pay Transparency */}
                <SectionContainer id='eoePay' color='white' type='contained'>
                    <SectionHeader color='navy' alignment='center' size='med' trim={true} tag={`  `}>Pay <span>Transparency</span></SectionHeader>
                    <p className={`basicText thinText narrowContentCol`}>
                         Precise Systems will not discharge or in any other manner discriminate against employees or applicants because they have inquired about, discussed, or disclosed their own pay or the pay of another employee or applicant. However, employees who have access to the compensation information of other employees or applicants as a part of their essential job functions cannot disclose the pay of other employees or applicants to individuals who do not otherwise have access to compensation information, unless the disclosure is (a) in response to a formal complaint or charge, (b) in furtherance of an investigation, proceeding, hearing, or action, including an investigation conducted by the employer, or (c) consistent with the Precise Systems legal duty to furnish information.
                    </p>
                    <p className={`footnote simple narrowContentCol`}>
                        California Applicants: Do not identify convictions under California Health &amp; Safety Code §§11357(b) or (c), 11360(b) (formerly subdivision (c) of section 11360), 11364, 11365, or 11550 related to marijuana offenses that occurred two or more years before the instant application. Also, do not identify any conviction for which the record has been judicially ordered sealed, expunged or statutorily eradicated, or any misdemeanor conviction for which probation has been successfully completed or otherwise discharged and the case has been judicially dismissed.
                    </p>
                </SectionContainer>
               
            </div> 
        </GeneralPageLayout>
    )
} 

export default EqualOpportunityEmployerPage